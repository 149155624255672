import React from 'react';
import JepsplSection from '../JepsplSection';
import WarningSection from '../WarningSection';

const WmwSection11 = () => {
  return (
    <>
      <WarningSection />
      <JepsplSection />
    </>
  );
};

export default WmwSection11;
