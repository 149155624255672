import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Drawer } from '@material-tailwind/react';
import Buttons from '../../../../components/common/Buttons';
import TextWinds from '../../../../components/common/TextWinds';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import {
  getBoostupInfo,
  selectBoostupInfo,
} from '../../../../store/features/boostupSlice';
import { useEffect, useState } from 'react';
import FullWidthPage from '../../../../components/layout/FullWidthPage';
import {
  BoostUpTypes,
  ChosenVariantDto,
  ProductContent,
} from '../../../../constants/types';
import { ProductContentType } from '../../../../enum';
import { Options } from '../../../../components/options/options';
import { getOrderItem } from '../../../../api/utils/common-purchase';
import { resetPurchaseInfo } from '../../../../store/features/purchaseSlice';
import { excuteAxiosPost } from '../../../../api/axios/call/testAxiosCall';
import { openAlert } from '../../../../store/features/modalSlice';
import { INTEREST_FREE_INSTALLMENT } from '../../../../data/statics-datas';
import BoostupWmwBookMain from './book/BoostupWmwBookMain';
import BoostupJSBookMain from './book/BoostupJSBookMain';
import BoostupHFBookMain from './book/BoostupHFBookMain';
import BoostupHJBookMain from './book/BoostupHJBookMain';

const BoostUpDetail = () => {
  const { boostupId } = useParams();
  const path = '/v1/product/' + boostupId;
  const dispatch = useAppDispatch();
  const boostupInfo: BoostUpTypes = useAppSelector(selectBoostupInfo);

  useEffect(() => {
    dispatch(getBoostupInfo({ url: path }));
  }, [path]);

  const isLoading = (): boolean => {
    if (!boostupInfo) {
      return true;
    } else {
      return false;
    }
  };

  const [openBottom, setOpenBottom] = useState(false);
  const openDrawerBottom = () => {
    setOpenBottom(true);
  };
  const closeDrawerBottom = () => {
    setOpenBottom(false);
  };

  const navigate = useNavigate();
  const onPurchaseHandle = async (
    childId: string,
    selectOptionData: ChosenVariantDto[]
  ) => {
    const path = '/v1/order/template/from-prod';
    const params = {
      order_item: getOrderItem({
        product_id: childId,
        variants: selectOptionData,
        options: [],
        enrolls: [],
      }),
    };
    await Promise.all([excuteAxiosPost(path, params)]).then((res) => {
      if (res) {
        dispatch(resetPurchaseInfo());
        navigate(
          { pathname: '/purchase' },
          { state: { purchaseId: res[0].data.id } }
        );
      }
    });
  };
  const onCartHandle = async (
    childId: string,
    selectItem: ChosenVariantDto[]
  ) => {
    if (selectItem.length > 0) {
      const path = '/v1/cart';
      const params = {
        order_item: getOrderItem({
          product_id: boostupInfo.id,
          variants: selectItem,
          options: [],
          enrolls: [],
        }),
      };
      await Promise.all([excuteAxiosPost(path, params)]).then((res) => {
        if (res) {
          navigate('/cart');
        }
      });
    }
  };

  const infoBtnClick = () => {
    dispatch(openAlert({ message: INTEREST_FREE_INSTALLMENT }));
  };

  return isLoading() ? null : (
    <FullWidthPage>
      {boostupInfo && (
        <div className="flex flex-col pt-10 gap-y-6">
          <TextWinds type="title_h2" xlType="title_h1" className="px-4">
            {boostupInfo.name}
          </TextWinds>
          <div className="flex flex-col lg:pt-[24px] gap-[32px] lg:flex-row">
            <div className="flex w-full shrink-0 lg:w-[275px]">
              <div className="bg-gray0 p-[10px] sticky top-20 h-fit w-full">
                <div className="relative w-full ">
                  <TextWinds type="title_h6" color="gray7">
                    설명
                  </TextWinds>
                  <TextWinds type="content_rel" className="pt-[4px]">
                    {boostupInfo.description}
                  </TextWinds>
                </div>
                <div className="bg-white mt-[16px] p-[10px]">
                  <div
                    className="flex items-center gap-[10px] p-[10px] cursor-pointer"
                    onClick={infoBtnClick}
                  >
                    <InformationCircleIcon className="relative w-[24px] h-[24px]" />
                    <TextWinds type="content_rel">무이자 할부 안내</TextWinds>
                  </div>
                  <div className="flex items-center flex-col justify-center w-full pt-[13px] gap-[13px]">
                    {/* <Buttons type="filled" disabled color={"purple5"}>
                      이용중 ~22.09.10 까지
                    </Buttons> */}
                    <Buttons
                      type="filled"
                      onClick={openDrawerBottom}
                      color={'purple5'}
                    >
                      구매하기
                    </Buttons>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col basis-full bg-gray3 overflow-hidden">
              {(() => {
                switch (boostupInfo.id) {
                  // WMW 교재 구매 페이지
                  case 'DT2tAtPS0TTZ91I8':
                    return <BoostupWmwBookMain />;

                  // JS1 교재 구매 페이지
                  case 'k6fN19d9lAIhnqS-':
                    return <BoostupJSBookMain />;

                  // JS2 교재 구매 페이지
                  case '8NF28UMHVb8pKBEk':
                    return <BoostupJSBookMain />;

                  // HF 교재 구매 페이지
                  case 'gFmNsScTxWA3k0XL':
                    return <BoostupHFBookMain />;

                  // HJ 교재 구매 페이지
                  case '0-htmPre-gUKUXLv':
                    return <BoostupHJBookMain />;

                  default:
                    return (
                      <>
                        {boostupInfo.contents.map((content: ProductContent) => {
                          switch (content.type) {
                            case ProductContentType.IMAGE:
                              return (
                                <img
                                  alt="boostup-detail-image"
                                  key={content.id}
                                  src={content.file.access_url}
                                  className="w-full"
                                />
                              );
                            case ProductContentType.VIDEO:
                            case ProductContentType.URL:
                            default:
                              return null;
                          }
                        })}
                      </>
                    );
                }
              })()}
            </div>

            <div
              className={`w-full p-4 fixed bottom-0 left-0 ${
                openBottom ? 'block' : 'hidden'
              }`}
            >
              <Drawer
                placement="bottom"
                open={openBottom}
                onClose={closeDrawerBottom}
                overlay={false}
                size={500}
                // className="items-center w-1/2 px-5 py-4 left-1/4 right-1/4"
                className="items-center w-full m-auto px-5 py-4 right-1/4 drop-shadow-xl rounded-2xl relative lg:w-1/2"
              >
                <Options
                  gubun="boostup"
                  shopInfo={boostupInfo}
                  chosenVariants={[...boostupInfo.option_variants].sort(
                    (a, b) => (a.name > b.name ? 1 : -1)
                  )}
                  closeDrawerBottom={closeDrawerBottom}
                  onCartHandle={onCartHandle}
                  onPurchaseHandle={onPurchaseHandle}
                />
              </Drawer>
            </div>
          </div>
        </div>
      )}
    </FullWidthPage>
  );
};

export default BoostUpDetail;
