import React from 'react';
import ChurchschoolEvent_25_1 from '../../../../../components/course/churchschool/event/ChurchschoolEvent_25_1';
import WmwSection8b from '../../../../../components/course/churchschool/wmw/WmwSection8b';
import JepsplSection from '../../../../../components/course/churchschool/JepsplSection';
import WarningSection from '../../../../../components/course/churchschool/WarningSection';
import WmwSection14 from '../../../../../components/course/churchschool/wmw/WmwSection14';

const BoostupJSBookMain = () => {
  return (
    <>
      <ChurchschoolEvent_25_1 />
      <WmwSection8b />
      <WarningSection />
      <JepsplSection />
      <WmwSection14 />
    </>
  );
};

export default BoostupJSBookMain;
