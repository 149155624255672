import BannerAward2024 from '../img/banner/Award2024.png';
import ReadyPlan from '../img/event/25_2_ready_plan.png';
import youtube from '../img/event/25_2_homeschool_youtube.png';
import icon1 from '../img/event/icon1.png';
import icon2 from '../img/event/icon2.png';
import icon3 from '../img/event/icon3.png';
import React, { ReactNode } from 'react';
import cursor from '../img/event/cursor.svg';

const HomeschoolEvent_25_2 = () => {
  return (
    <>
      {/* 배너 영역 */}
      <img src={BannerAward2024} alt="Award2024" className="w-full m-auto" />

      {/* 프로모션 섹션 1 */}
      <div className="bg-[#ffb218] flex justify-center items-center relative">
        <div className="flex flex-col gap-10 py-20 px-5 max-w-[720px] m-auto">
          <div className="flex max-w-[480px] m-auto">
            <img src={ReadyPlan} alt="ReadyPlan" />
          </div>
          <div className="flex flex-col justify-center items-center gap-5 font-bold text-lg">
            <ImogiChildrenBox
              icon={icon1}
              text="벌써 새 학기가 시작한다고!?"
              bgColor="bg-[#5b6e58]"
              textColor="text-white"
              iconBgColor="bg-[#ffb218]"
            >
              <>
                “벌써 새 학기가 시작한다고!?
                <br className="hidden sm:block" /> 눈 깜짝할 새 흘려 보낸 겨울
                방학, 어쩌죠?“
              </>
            </ImogiChildrenBox>

            <ImogiChildrenBox
              icon={icon2}
              text="이번 겨울 방학 목표였던 우리 아이 영어와 신앙교육"
              bgColor="bg-[#4a6d48]"
              textColor="text-white"
              iconBgColor="bg-[#ffb218]"
              align="right"
            >
              <>
                “이번 겨울 방학 목표였던 우리 아이 영어와 신앙교육,
                <br className="hidden sm:block" />
                이제라도 제대로 시작할 방법 없을까요?”
              </>
            </ImogiChildrenBox>
            <ImogiChildrenBox
              icon={icon3}
              text="“3월 새 학기 전에는 꼭!"
              bgColor="bg-[#2b4929]"
              textColor="text-white"
              iconBgColor="bg-[#ffb218]"
            >
              <>
                “3월 새 학기 전에는 꼭!
                <br className="hidden sm:block" />
                우리 아이에게 영어 흥미가 생기길 바라고 있어요!”
              </>
            </ImogiChildrenBox>
          </div>

          <section className="text-center">
            <div className="text-lg sm:text-2xl font-semibold">
              새학기 준비를 위한 필수 전략 공개!
            </div>
            <div
              className="text-[26px] sm:text-4xl font-extrabold
            underline decoration-[#FFD973] decoration-[10px] underline-offset-[-2px] [box-decoration-break:clone] [text-decoration-skip-ink:none]"
            >
              젭스홈스쿨 2월 온라인 설명회
            </div>
          </section>

          <div className="relative w-full max-w-[480px] m-auto">
            <img src={youtube} alt="conference_thumbnail" />
          </div>

          <DoubleLineButton
            onClick={() => {
              window.open('https://forms.gle/hYg6Q4YQ4tMcBFb58');
            }}
          >
            <div className="text-xl sm:text-4xl font-bold p-3 sm:p-5">
              온라인 설명회 신청하기
            </div>
          </DoubleLineButton>
        </div>
      </div>

      {/* 프로모션 섹션 2 */}
      <div className="bg-[#2B492A] flex justify-center items-center">
        <div className="flex flex-col gap-10 px-5 py-20 max-w-[720px] ">
          <section className="flex flex-col gap-3">
            {/* 타이틀 */}
            <div className="font-extrabold text-3xl sm:text-4xl text-[#FFB219] text-center">
              우리 아이 영어 새학기,
              <br />
              젭스와 함께 하세요!
            </div>

            {/* 텍스트 */}
            <div className="text-base sm:text-xl font-medium underline underline-offset-4 decoration-[#FFB219] text-center text-white">
              지금이 우리 아이 영어&신앙 교육의 골든 타임!
              <br />
              젭스홈스쿨 2월 새 학기 대비 혜택으로
              <br />
              영어와 신앙이 레벨업 될 골든 타임을 꽉 잡으세요!
            </div>
          </section>

          {/* 박스 */}
          <section className="flex flex-col gap-5 w-full">
            <TextBox>
              <div className="text-black font-bold">
                영어교육 대가 정철 선생님의 교수법으로
                <br />
                아이들의{' '}
                <span className="text-[#2B492A] underline underline-offset-[-2px] decoration-8 decoration-[#FFD973] [box-decoration-break:clone] [text-decoration-skip-ink:none]">
                  영어 흥미를 자극!
                </span>
              </div>
            </TextBox>
            <TextBox>
              <div className="text-black font-bold">
                성경에 기반한 교육 커리큘럼으로
                <br />
                <span className="text-[#2B492A] underline underline-offset-[-2px] decoration-8 decoration-[#FFD973] [box-decoration-break:clone] [text-decoration-skip-ink:none]">
                  영어&신앙 올인원 솔루션
                </span>
                !
              </div>
            </TextBox>
            <TextBox>
              <div className="text-black font-bold">
                <span className="text-[#2B492A] underline underline-offset-[-2px] decoration-8 decoration-[#FFD973] [box-decoration-break:clone] [text-decoration-skip-ink:none]">
                  월 4만 원대
                </span>
                로 시작해
                <br />
                부담 없이 배우는 성경적 영어 교육!
              </div>
            </TextBox>
          </section>

          <div className="text-xl sm:text-2xl font-bold text-white text-center">
            ⭐️2월 한정 혜택으로⭐️
            <br /> 우리 아이 골든 타임 잡고!
            <br /> 든든하게 새 학기 시작하자!
          </div>

          <DoubleWhiteLineButton
            onClick={() => {
              window.open('https://forms.gle/hYg6Q4YQ4tMcBFb58');
            }}
          >
            <div className="text-xl sm:text-4xl font-bold p-3 sm:p-5">
              2월 홈스쿨 등록 혜택 받기
            </div>
          </DoubleWhiteLineButton>
        </div>
      </div>
    </>
  );
};

interface ImogiChildBoxProps {
  children: ReactNode;
  icon: string;
  text: string;
  align?: 'left' | 'right';
  bgColor?: string;
  textColor?: string;
  iconBgColor?: string;
}

const ImogiChildrenBox = ({
  children,
  icon,
  text,
  align = 'left',
  bgColor = 'bg-white/65',
  textColor = 'text-black',
  iconBgColor = 'bg-white/65',
}: ImogiChildBoxProps) => {
  const margin = '18%';
  const isRightAligned = align === 'right';

  return (
    <div className={`w-full`}>
      <div
        className={`flex items-center gap-2  ${
          isRightAligned ? 'justify-end' : 'justify-start'
        }
        ${bgColor} rounded-lg p-3 sm:p-5 
        ${textColor} text-xs sm:text-sm
        `}
        style={{
          textAlign: align,
          boxSizing: 'border-box',
          marginLeft: isRightAligned ? margin : 0,
          marginRight: isRightAligned ? 0 : margin,
        }}
      >
        <div style={{ order: isRightAligned ? 1 : 2 }}>{children}</div>
        <img
          src={icon}
          alt={text}
          className={`w-10 sm:w-14 h-10 sm:h-14 p-2 ${iconBgColor} rounded-full`}
          style={{ order: isRightAligned ? 2 : 1 }}
        />
      </div>
    </div>
  );
};

const DoubleLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => {
  return (
    <div
      className={`border-[2px] sm:border-[3px] border-[#2b492a] rounded-[24px] sm:rounded-[41px] p-1 sm:p-[7px] cursor-pointer`}
      style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
      onClick={onClick}
    >
      <div
        className={`rounded-[17px] sm:rounded-[30px] text-center bg-[#2b492a] hover:bg-[#274226] text-white`} // hover : #2b492a의 90%
      >
        {children}
      </div>
    </div>
  );
};

const DoubleWhiteLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <div
    className="border-[2px] sm:border-[3px] border-white/70 rounded-[24px] sm:rounded-[41px] p-1 sm:p-[7px] cursor-pointer relative"
    style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    onClick={onClick}
  >
    <div
      className={`rounded-[17px] sm:rounded-[30px] text-center bg-[#ffb218] border-4 border-white hover:bg-[#e6a016] text-black`} // hover : #ffb218의 90%
    >
      {children}
    </div>
    <div className="absolute top-[65%] right-[5%] w-10 sm:w-14 h-10 sm:h-14">
      <img src={cursor} alt="cursor" className="w-10 sm:w-14 h-10 sm:h-14" />
    </div>
  </div>
);

const TextBox = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className="rounded-3xl bg-white text-center font-bold text-base sm:text-2xl w-full sm:px-10 px-7 sm:py-7 py-5"
      style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    >
      {children}
    </div>
  );
};

export default HomeschoolEvent_25_2;
