import React from 'react';
import { HalfLayout } from '../../../components/layout/HalfLayout';
import ResponseTypo from '../../../components/ResponseTypo';

const TimeLineData: TimeLineDataType[] = [
  { time: '2010.04', title: 'WHO MADE THE WORLD? 개발' },
  { time: '2010.10', title: '(주)젭스 법인 설립' },
  { time: '2012.05', title: '제1회 국민일보 미션어워드 수상' },
  { time: '2013.10', title: '국민일보 주최 기독교 교육 브랜드 대상 수상' },
  { time: '2015.01', title: '젭스영어성경홈스쿨 론칭' },
  { time: '2018.11', title: '젭스폰 론칭' },
  { time: '2023.06', title: '정철의 A.D.영어학습법 출간' },
  { time: '2024.08', title: '영어로 그림전도 출간' },
];

interface TimeLineDataType {
  time: string;
  title: string;
}

const TimeLineRow = ({ time, title }: TimeLineDataType) => {
  return (
    <div className="h-[55px] justify-start items-center gap-[15px] lg:gap-[30px] inline-flex [&:nth-last-child(1)_span]:hidden">
      <div className="shrink-0 w-[48px] lg:w-[60px] text-sm lg:text-lg font-normal font-['Pretendard'] leading-relaxed">
        {time}
      </div>
      <div className="shrink-0 static basis-[14px] h-[14px] lg:basis-[16px] lg:h-[16px] bg-spotViolet/[0.3] rounded-full border-2 border-spotViolet">
        <span>
          <div className="grayBar w-[3px] h-[29px] relative top-[18px] left-[4px] lg:left-[5px] bg-gray-300 rounded-full" />
        </span>
      </div>
      <div className="grow shrink text-sm lg:text-lg font-medium font-['Pretendard'] leading-normal whitespace-wrap">
        {title}
      </div>
    </div>
  );
};
{
  /* TimeLine */
}
export const StoryTimeLine = () => {
  return (
    <section className="gradient-sky p-5 lg:py-14">
      <HalfLayout
        className="py-9 max-w-[1024px] m-auto lg:px-0 lg:py-0"
        left={
          <div className="text-white">
            <ResponseTypo variant_sm={'h4'} variant_lg={'h2'}>
              젭스는 차원이 다른
              <br />
              초레벨 바이블 영어교육을
              <br />
              제공합니다.
            </ResponseTypo>

            <ResponseTypo
              variant_sm={'h5'}
              variant_lg={'h4'}
              className="mt-5 font-bold lg:mt-7"
            >
              <span className="pr-1">전국 교회</span>
              <span className="text-teal">7천 여개</span>
            </ResponseTypo>

            <ResponseTypo
              variant_sm={'h5'}
              variant_lg={'h4'}
              className="mt-3 font-bold lg:mt-5"
            >
              <span className="pr-1">주일학교 코스 수료 교사</span>
              <span className="text-teal">1만 3천 여명</span>
            </ResponseTypo>

            <ResponseTypo
              variant_sm={'h5'}
              variant_lg={'h4'}
              className="mt-3 font-bold lg:mt-5"
            >
              <span className="pr-1">홈스쿨 코스 수료 교사</span>
              <span className="text-teal">5천 여명</span>
            </ResponseTypo>
            <ResponseTypo
              variant_sm={'small'}
              variant_lg={'small'}
              className="mt-3 lg:mt-6"
            >
              *2023년 12월 기준
            </ResponseTypo>
          </div>
        }
        right={
          <div className="flex flex-col bg-white radius-lg px-5 rounded-lg text-spotViolet lg:py-6">
            {TimeLineData.map((data, index) => (
              <TimeLineRow key={index} time={data.time} title={data.title} />
            ))}
          </div>
        }
      />
    </section>
  );
};
