import Award2024Img from '../img/banner/Award2024.png';
import HeadText1 from '../img/event/text-1.png';
import HeadText2 from '../img/event/text-2.png';
import icon1 from '../img/event/icon1.png';
import icon2 from '../img/event/icon2.png';
import icon3 from '../img/event/icon3.png';
import conference_thumbnail from '../img/conference_thumbnail.png';
import mobile_device from '../img/mobile_device.png';
import React, { ReactNode } from 'react';
import cursor from '../img/event/cursor.svg';
import { isWithin } from '../../../../api/utils/util-func';
import NewYear from '../../homeschool/img/banner/banner-newyear.png';
const HomeschoolEvent_25_1 = () => {
  const gradientStyle = {
    position: 'relative' as 'relative',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(180deg, #FF9AA2 0%, #FFB7B2 50%, white 100%)',
  };

  const svgBackground = `url("data:image/svg+xml,${encodeURIComponent(`
    <svg width="400" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="redToTransparent" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stop-color="#ff5555" stop-opacity="0.8" />
          <stop offset="40%" stop-color="#ff8888" stop-opacity="0.3" />
          <stop offset="70%" stop-color="red" stop-opacity="0" />
        </linearGradient>
        <filter id="glow">
          <feGaussianBlur stdDeviation="6" result="blur" />
          <feMerge>
            <feMergeNode in="blur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle cx="100" cy="100" r="80" fill="url(#redToTransparent)" filter="url(#glow)" />
    </svg>
  `)}")`;

  return (
    <>
      {isWithin('2025-01-27', '2025-01-30') ? (
        <img src={NewYear} alt="NewYear2025" className="w-full m-auto" />
      ) : (
        <img src={Award2024Img} alt="Award2024" className="w-full m-auto" />
      )}
      <div style={gradientStyle}>
        <div
          className="flex flex-col gap-10 py-[20%] px-5"
          style={{
            backgroundImage: svgBackground,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            backgroundSize: 'contain',
            position: 'relative',
            width: '100%',
            maxWidth: '720px',
          }}
        >
          <div>
            <div className="text-hakgyoansim text-center text-xs md:text-2xl font-bold text-white">
              우리 아이 영어도 영성도 쑥쑥 자라날 한 해를 기대하며
            </div>
            <img src={HeadText1} alt="HeadText1" className="w-full m-auto" />
          </div>
          <div className="flex flex-col justify-center items-center gap-5 font-bold text-lg">
            <ImogiTextBox
              icon={icon1}
              text="“우리 아이 영어와 신앙을 위한 새해 다짐으로 2025년을 시작하고 싶어요! “"
            />
            <ImogiTextBox
              icon={icon2}
              text="“신앙과 영어 두 마리 토끼를 다 잡는 결단을 지금 내려야겠어요!!!”"
              align="right"
            />
            <ImogiTextBox
              icon={icon3}
              text="“우리 아이 영어와 신앙을 위한 새해 다짐으로 2025년을 시작하고 싶어요! “"
            />
          </div>
          <img src={HeadText2} alt="HeadText2" className="w-full m-auto" />
          <div className="relative w-full max-w-[720px] m-auto">
            <img src={mobile_device} alt="conference_thumbnail" />
            <img
              src={conference_thumbnail}
              alt="conference_thumbnail"
              className="p-[10%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
          <DoubleBlackLineButton
            onClick={() => {
              window.open('https://forms.gle/hYg6Q4YQ4tMcBFb58');
            }}
          >
            <div className="text-xl md:text-4xl font-bold p-3 md:p-5">
              온라인 설명회 신청하기
            </div>
          </DoubleBlackLineButton>
        </div>
      </div>
      <div className="bg-[#FA5735] text-center text-white py-20">
        <div className="max-w-[720px] m-auto px-5 flex flex-col gap-10">
          <section className="flex flex-col gap-3">
            <div className="font-extrabold text-3xl md:text-4xl">
              우리 아이 영어 새해 다짐,
              <br />
              젭스가 이렇게 도울게요!
            </div>
            <div className="text-base md:text-2xl font-medium">
              우리 아이 영어&신앙을 위한
              <br />{' '}
              <span className="text-[#FFD9A5] font-bold">
                가장 설득력있는 솔루션
              </span>
              을 자신 있게 제안합니다!
            </div>
          </section>

          <section className="max-w-[480px] m-auto flex flex-col gap-5">
            <TextBox>
              <div className="text-black font-bold">
                영어 성경을 교재 삼아
                <br />
                <span className="text-[#FA5735]">
                  성경적 사고력과 복음적 습득력
                </span>{' '}
                장착!
              </div>
            </TextBox>
            <TextBox>
              <div className="text-black font-bold">
                영어와 신앙을 한 번에 해결하는
                <br />
                합리적인 <span className="text-[#FA5735]">올인원 솔루션</span>!
              </div>
            </TextBox>
            <TextBox>
              <div className="text-black font-bold">
                불경기에도{' '}
                <span className="text-[#FA5735]">홈스쿨로, 공부방으로</span>
                <br />
                멈추지 않는{' '}
                <span className="text-[#FA5735]">성경적 영어 교육</span>!
              </div>
            </TextBox>
          </section>

          <section className="flex flex-col gap-2">
            <div className="text-xl md:text-2xl font-bold">
              2025년 가장 현명한 결단을 위한
              <br />
              2025년 첫 번째 홈스쿨 혜택✨
            </div>
            <div className="text-base md:text-xl font-medium">
              👇지금 바로 혜택 받고 시작하세요!
            </div>
            <DoubleWhiteLineButton
              onClick={() => {
                window.open('https://forms.gle/hYg6Q4YQ4tMcBFb58');
              }}
            >
              <div className="text-xl md:text-4xl font-bold p-3 md:p-5">
                1월 홈스쿨 등록 혜택 받기
              </div>
            </DoubleWhiteLineButton>
          </section>
        </div>
      </div>
    </>
  );
};

interface ImogiTextBoxProps {
  icon: string;
  text: string;
  align?: 'left' | 'right';
}

const ImogiTextBox = ({ icon, text, align = 'left' }: ImogiTextBoxProps) => {
  const margin = '15%';
  const isRightAligned = align === 'right';

  return (
    <div
      className="flex items-center gap-2 bg-white/65 rounded-lg p-3 md:p-5 text-xs md:text-base"
      style={{
        marginLeft: isRightAligned ? margin : 0,
        marginRight: isRightAligned ? 0 : margin,
        textAlign: align,
        boxSizing: 'border-box',
      }}
    >
      <div style={{ order: isRightAligned ? 1 : 2 }}>{text}</div>
      <img
        src={icon}
        alt={`Text box icon - ${text.slice(0, 20)}...`}
        className="w-10 md:w-14 h-10 md:h-14 p-2 bg-white rounded-full"
        style={{ order: isRightAligned ? 2 : 1 }}
      />
    </div>
  );
};

const DoubleBlackLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <div
    className="border-[2px] md:border-[3px] border-black rounded-[24px] md:rounded-[41px] p-1 md:p-[7px] cursor-pointer"
    style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    onClick={onClick}
  >
    <div className="rounded-[17px] md:rounded-[30px] text-center bg-black hover:bg-gray-900 text-white">
      {children}
    </div>
  </div>
);

const DoubleWhiteLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <div
    className="border-[2px] md:border-[3px] border-white/70 rounded-[24px] md:rounded-[41px] p-1 md:p-[7px] cursor-pointer relative"
    style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    onClick={onClick}
  >
    <div className="rounded-[17px] md:rounded-[30px] text-center bg-black border-4 border-white hover:bg-gray-900 text-white">
      {children}
    </div>
    <div className="absolute top-[65%] right-[5%] w-10 md:w-14 h-10 md:h-14">
      <img src={cursor} alt="cursor" className="w-10 md:w-14 h-10 md:h-14" />
    </div>
  </div>
);

const TextBox = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className="rounded-3xl bg-white text-center font-bold text-lg md:text-2xl w-full md:px-10 px-7 md:py-7 py-5"
      style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    >
      {children}
    </div>
  );
};

export default HomeschoolEvent_25_1;
