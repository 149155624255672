import React, { useEffect, useMemo } from 'react';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import HFbutton from '../common/input/HFbutton';
import ChannelService from '../../api/ChannelService';
import { PopupType } from '../../constants/popupData';
import dayjs from 'dayjs';
import { Skeleton } from 'antd';
import { isWithin } from '../../api/utils/util-func';

const filteredPopupData = (popupList: PopupType[]): PopupType => {
  if (popupList.length === 0) {
    throw new Error('팝업 데이터가 없습니다.');
  }

  // default 팝업 검증 및 찾기
  const defaultPopups = popupList.filter((popup) => popup.default === true);
  if (defaultPopups.length > 1) {
    throw new Error('팝업의 디폴트 데이터는 최대 하나만 가능합니다.');
  }
  const defaultPopup = defaultPopups[0];

  // 유효한 기간의 팝업 찾기
  const isDateValid = (popup: PopupType): boolean => {
    if (!popup.date) return false;
    return isWithin(popup.date.start, popup.date.end);
  };

  // 기간이 유효한 팝업들을 endDate 기준으로 정렬
  const validPopups = popupList.filter(isDateValid).sort((a, b) => {
    if (!a.date?.end || !b.date?.end) return 0;
    return a.date.end.isBefore(b.date.end) ? -1 : 1;
  });

  // 유효한 기간의 팝업이 있으면 그 중 가장 빠른 endDate를 가진 팝업을,
  // 없으면 default 팝업을 반환
  return validPopups[0] ?? defaultPopup;
};

interface ModalPopupProps {
  list: PopupType[];
}
const ModalPopup = ({ list }: ModalPopupProps) => {
  const POPUP_BLOCK = 'popupBlockDay';
  const dispatch = useAppDispatch();
  const [block, setBlock] = React.useState(false);
  const { img, link, type, size } = filteredPopupData(list);

  const style = useMemo(
    () => `flex flex-col gap-1 p-1 max-w-[${size}px] w-full bg-white  rounded`,
    [size]
  );

  const keyHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeHandler();
    }
  };

  useEffect(() => {
    ChannelService.hideChannelButton();
    const dayBlock = localStorage.getItem(POPUP_BLOCK);
    const day = dayjs(dayBlock, 'YYYY. M. D.');

    // 하루간 보지 않기를 누른 날짜가 오늘이라면 팝업을 닫는다.
    if (day && day.isSame(dayjs(), 'day')) {
      closeHandler();
    }

    // escape 키로 팝업 닫기
    window.addEventListener('keydown', keyHandler);

    return () => {
      ChannelService.showChannelButton();
      window.removeEventListener('keydown', keyHandler);
    };
  }, []);

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const dayBlockHandler = () => {
    localStorage.setItem(POPUP_BLOCK, new Date().toLocaleDateString());
    closeHandler();
  };

  const clickHandler = () => {
    if (!link) return;
    window.open(link, type);
  };
  return (
    <div className={style}>
      {!block && (
        <Skeleton.Image active style={{ width: '100%', height: '300px' }} />
      )}
      <img
        className={`w-full ${
          link === undefined ? '' : 'cursor-pointer'
        } object-cover ${block ? '' : 'hidden'}`}
        src={img}
        onClick={clickHandler}
        onLoad={() => setBlock(true)}
      />
      <div className="flex gap-3 p-2">
        <HFbutton type="Outlined" onClick={dayBlockHandler}>
          하루간 보지 않기
        </HFbutton>
        <HFbutton onClick={closeHandler}>닫기</HFbutton>
      </div>
    </div>
  );
};

export default ModalPopup;
