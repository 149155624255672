import React from 'react';
import { motion } from 'framer-motion';
import { CircleIcon, NeumophismAccordion } from './WmwComp';
import illustration from './img/section7_wmwpage.png';
import calendar from '../img/sec07-calender-dynamic-gradient.png';

const WmwSection7 = () => {
  return (
    <>
      {/* 흰배경 영역 */}
      <div className="bg-white p-3 py-10 md:p-10">
        <div className="max-w-[520px] m-auto">
          <NeumophismAccordion
            title={
              <div className="flex-1 text-base md:text-xl font-['Pretendard']">
                <div className="font-bold">WMW Series에만</div>
                <div>적용된 [삽화기억술]을 아시나요?</div>
              </div>
            }
          >
            <IllustrationMnemonic />
          </NeumophismAccordion>
        </div>
      </div>

      {/* 회색배경 영역 */}
      <div className="bg-[#d5d5d5] flex justify-center items-center p-3 pt-10 pb-28">
        <div className="w-full max-w-[520px] flex flex-col justify-center gap-6 md:gap-8">
          {/*타이틀 */}
          <section className="flex flex-col gap-1">
            <img
              src={calendar}
              alt="calendar"
              className="aspect-square object-contain w-24 h-24 m-auto"
            />
            <div className="text-center font-bold font-['Pretendard'] text-2xl sm:text-3xl ">
              <span className="text-[#875cff]">WMW</span> Series 커리큘럼 과정
            </div>
          </section>

          {/* 컨텐츠 */}
          <section className="flex gap-5 md:gap-7 m-auto justify-around items-baseline">
            <motion.div
              className="relative"
              initial={{ y: -20, opacity: 1 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <div
                className="bg-gray-200 rounded-2xl w-[100px] h-20 p-4 m-auto 
                text-center font-['Pretendard'] text-sm whitespace-nowrap
                flex items-end justify-center
                absolute top-10 left-1/2 -translate-x-1/2"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                Who Made
                <br />
                the World?
              </div>
              <div
                className="bg-white rounded-2xl p-4 w-fit m-auto
                text-lg font-bold text-[#875cff] text-center font-['Pretendard']
                relative"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                1년
              </div>
            </motion.div>

            <CircleIcon width={22} height={22} fontSize={50} text="+" />

            <motion.div
              className="relative"
              initial={{ y: -20, opacity: 1 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <div
                className="bg-gray-200 rounded-2xl w-fit h-20 p-4 m-auto 
                text-center font-['Pretendard'] text-sm whitespace-nowrap
                flex items-center justify-center
                absolute top-10 left-1/2 -translate-x-1/2"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                Jesus Story
                <br />
                (Ⅰ, Ⅱ 각 1년)
              </div>
              <div
                className="bg-white rounded-2xl p-4 w-fit m-auto
                text-lg font-bold text-[#875cff] text-center font-['Pretendard']
                relative"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                2년
              </div>
            </motion.div>

            <CircleIcon width={22} height={22} fontSize={50} text="=" />

            <motion.div
              className="relative"
              initial={{ y: -20, opacity: 1 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <div
                className="bg-gray-200 rounded-2xl w-[100px] p-4 h-20 m-auto 
                text-center font-['Pretendard'] text-sm whitespace-nowrap
                flex items-center justify-center
                absolute top-10 left-1/2 -translate-x-1/2"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                총 학습 기간
              </div>
              <div
                className="bg-[#875cff] rounded-2xl p-4 w-fit m-auto
                text-lg font-bold text-white text-center font-['Pretendard']
                relative"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                3년
              </div>
            </motion.div>
          </section>
        </div>
      </div>
    </>
  );
};

const IllustrationMnemonic = () => {
  return (
    <div className="flex flex-col gap-5 md:my-5">
      <div className="border border-[#875cff] rounded-[46.50px] font-bold text-lg md:text-xl p-1 px-5 m-auto">
        <span className="text-[#875cff]">삽화기억술</span>이란?
      </div>
      <div className="text-center text-sm sm:text-base">
        삽화기억술은 정철 선생님의 특허 받은 교수법으로,
        <br />
        청크 단위로 궁금한 순서를 표현한 이미지와
        <br />
        이미지의 위치를 통해
        <br />
        영어 문장의 구조를 이해하고 스토리를 이미지화하여
        <br />
        자연스럽게 기억하게 하는 영어학습 방법입니다.
      </div>
      <img
        src={illustration}
        alt="삽화기억술"
        className="aspect-auto object-contain max-w-[280px] w-full m-auto"
      />
    </div>
  );
};

export default WmwSection7;
