import Cookies from 'js-cookie';

export enum CookieKey {
  NEW_RECITATION = 'NEW_RECITATION',
  USER_INFO = 'USER_INFO',
}
const isProduction = () => {
  const env = process.env.REACT_APP_JEBS_APP_URL;
  if (env.includes('dev')) {
    return false;
  }
  return true;
};
export const createCookie = <GData>(key: CookieKey) => {
  // const domain = isProduction() ? 'jebs.kr' : undefined;
  return {
    setCookie: (value: GData, days?: number) => {
      Cookies.set(key, JSON.stringify(value), {
        expires: days,
        domain: 'jebs.kr',
        path: '/',
        secure: true,
        sameSite: 'None',
      });
    },
    getCookie: (): GData => JSON.parse(Cookies.get(key)),
    removeCookie: () => {
      Cookies.remove(key);
    },
  };
};
