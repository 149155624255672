export const extensionLink = (courseId: string) => {
  switch (courseId) {
    case 'gwapIhnDk5Tamuzq':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/18Mm87u5V0hU6B57`; // 홈스쿨 KIDS
    case 'oGLrlIoLt1WYnozn':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/WvA7mOd2zVCpgjSC`; // 홈스쿨 BASIC
    case '8XOXKtAuBH19PLkp':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/o11lpOrtYZvft2kS`; // 홈스쿨 ADVANCED
    case 'mEXF7BCUGXSkN-U8':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/YCs71D_HpHuzaIn-`; // 주일학교 HFHJ
    case '-pq1VyU-tT5QeubB':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/vKPAt48Z82J--OQW`; // 주일학교 WMW
    case 'Mn-J3sgCqapZFS4o':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/yn77N1jwEoadGi9k`; // 영성통
    case 'brGq1O88VZQRA671':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/dyU762lu6RonOLhT`; // 소그룹 야교보서
    case 'UXE6OnWp0wN0TEIy':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/Cb1rlUxvKLutuFyT`; // 소그룹 요한일서
    case 'DkRk2HmNL7jw4cB_':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/C1GN6X0nwhF9y4m-`; // 소그룹 성영암
    case 'o_pYmtvM3RFvBhRD':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/rH76pcPI7w89wgFB`; // 소그룹 요나서
    case 'fJl8XDPPytKwSeoU':
      return `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/9x2g5R-9DMQMAHSX`; // 소그룹 영복전
    default:
      return '';
  }
};
