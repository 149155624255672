import React, { cloneElement, Fragment } from 'react';
import {
  Button,
  IconButton,
  Option,
  Select,
  Typography,
} from '@material-tailwind/react';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  BoostUpTypes,
  ChosenVariant,
  ChosenVariantDto,
  CourseInfoType,
  ShopInfoProps,
} from '../../constants/types';
import { numberWithCommas } from '../../api/utils/format/numbers';
import { useSearchParams } from 'react-router-dom';

interface OptionsItemProps {
  gubun: string;
  shopInfo: ShopInfoProps | CourseInfoType | BoostUpTypes;
  chosenVariants: ChosenVariant[];
  closeDrawerBottom: any;
  onCartHandle: any;
  onPurchaseHandle: any;
}

const parseOptionItems = (
  searchParams: URLSearchParams
): ChosenVariantDto[] => {
  const itemsString = searchParams.get('optionItems');
  if (!itemsString) return [];
  return JSON.parse(itemsString);
};

const stringifyOptionItems = (items: ChosenVariantDto[]): string => {
  return JSON.stringify(items);
};

export const Options = ({
  gubun,
  shopInfo,
  chosenVariants,
  closeDrawerBottom,
  onCartHandle,
  onPurchaseHandle,
}: OptionsItemProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const optionItem = parseOptionItems(searchParams);
  const totalQuantity = optionItem.reduce((acc, cur) => acc + cur.quantity, 0);

  const minQuantity = (() => {
    try {
      return shopInfo.option_products[0].option_constraints[0].min_quantity;
    } catch {
      return shopInfo.base_quantity;
    }
  })();

  const getOptionPrice = (option: ChosenVariant) => {
    switch (gubun) {
      case 'boostup':
      case 'shop':
        return shopInfo.current_price + option.additional_price;
      case 'course':
        return (
          shopInfo.option_products?.find(
            (v1) => v1.option_product.id === option.product_id
          ).option_product.origin_price + option.additional_price
        );
      default:
        return 0;
    }
  };

  const selectOption = (str: string) => {
    const option = chosenVariants.find((el) => el.id === str);

    if (!option) return;

    const existingItemIndex = optionItem.findIndex(
      (el) => el.variant.id === str
    );

    if (existingItemIndex >= 0) {
      const updatedItems = [...optionItem];
      const existingItem = updatedItems[existingItemIndex];

      updatedItems[existingItemIndex] = {
        ...existingItem,
        quantity: existingItem.quantity + 1,
        totalPrice: (existingItem.quantity + 1) * getOptionPrice(option),
      };
      searchParams.set('optionItems', stringifyOptionItems(updatedItems));
      setSearchParams(searchParams);
    } else {
      const newData: ChosenVariantDto = {
        baseQuantity: shopInfo.base_quantity,
        quantity: shopInfo.base_quantity,
        totalPrice: shopInfo.base_quantity * getOptionPrice(option),
        variant: { ...option },
      };

      searchParams.set(
        'optionItems',
        stringifyOptionItems([...optionItem, newData])
      );
      setSearchParams(searchParams);
    }
  };

  const quantityHandler = (idx: number, delta: number) => {
    const updatedItems = optionItem.map((item, index) =>
      index === idx
        ? {
            ...item,
            quantity: item.quantity + delta,
            totalPrice: (item.quantity + delta) * getOptionPrice(item.variant),
          }
        : item
    );

    searchParams.set('optionItems', stringifyOptionItems(updatedItems));
    setSearchParams(searchParams);
  };

  const onRemoveItem = (idx: number) => {
    const modifiedArray = [
      ...optionItem.slice(0, idx),
      ...optionItem.slice(idx + 1),
    ];

    if (modifiedArray.length === 0) {
      searchParams.delete('optionItems');
    } else {
      searchParams.set('optionItems', stringifyOptionItems(modifiedArray));
    }
    setSearchParams(searchParams);
  };

  const eventCartHandle = () => {
    onCartHandle(shopInfo.id, optionItem);
  };

  const eventPurchaseHandle = () => {
    if (minQuantity > totalQuantity) {
      alert('최소 옵션은 ' + minQuantity + '개 이상 선택해주세요.');
      return;
    }
    onPurchaseHandle(shopInfo.id, optionItem);
  };

  let buttonDisabled: boolean;
  if (shopInfo.option_products) {
    buttonDisabled = false;
  } else {
    buttonDisabled = optionItem.length === 0;
  }

  return (
    <Fragment>
      <section>
        <div className="flex flex-row items-center gap-8 mb-2.5">
          <div className="flex flex-col md:flex-row items-left md:items-center w-full  text-sm md:text-base border-b pb-1">
            <Typography
              variant="h5"
              className="font-bold self-start py-2.5  text-sm md:text-base"
            >
              옵션 선택 {minQuantity > 1 && ` : 최소 수량 ${minQuantity}개`}
            </Typography>
            {minQuantity > 1 && ` - [ 총 ${totalQuantity}개 선택 ]`}
          </div>
          <div className="flex items-center ml-auto">
            <IconButton
              variant="text"
              color="blue-gray"
              onClick={() => closeDrawerBottom()}
            >
              <XMarkIcon className="!w-[30px] !h-[30px]" />
            </IconButton>
          </div>
        </div>
      </section>
      <section className="min-h-60 max-h-80 overflow-y-auto">
        {shopInfo && (
          <div className="flex flex-col mb-2.5 mt-1">
            <Select
              label="옵션 선택"
              selected={(element) =>
                element &&
                cloneElement(element, {
                  disabled: true,
                  className:
                    'flex items-center opacity-100 px-0 gap-2 pointer-events-none',
                })
              }
              onChange={(e) => selectOption(e)}
            >
              {chosenVariants &&
                chosenVariants.map((item) => (
                  <Option className="p-2" value={item.id} key={item.id}>
                    <Typography>{item.name}</Typography>
                  </Option>
                ))}
            </Select>
            {gubun !== 'shop' && shopInfo.option_products.length > 0 && (
              <div className="mt-5 flex justify-between">
                <div>{shopInfo.name}</div>
                <div>{numberWithCommas(shopInfo.current_price)} 원</div>
              </div>
            )}
            {optionItem &&
              optionItem.map((item, index: number) => (
                <div key={index} className="flex flex-col mx-4 my-1">
                  <div className="flex flex-row items-center gap-4">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item.variant?.name}
                    </Typography>
                    <div className="flex items-center ml-auto">
                      <IconButton
                        variant="text"
                        color="blue-gray"
                        onClick={() => onRemoveItem(index)}
                      >
                        <XMarkIcon className="w-5 h-5" />
                      </IconButton>
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2 px-2 py-2">
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={() => quantityHandler(index, -1)}
                      disabled={item.quantity <= item.baseQuantity}
                    >
                      <MinusIcon className="w-2 h-2" />
                    </Button>

                    <Typography>{item.quantity}</Typography>

                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={() => quantityHandler(index, 1)}
                    >
                      <PlusIcon className="w-2 h-2" />
                    </Button>
                    <div className="flex items-center ml-auto gap-2">
                      <Typography className="items-center font-medium">
                        {numberWithCommas(item.totalPrice)}
                      </Typography>
                      <Typography className="items-center font-medium">
                        원
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </section>
      <section>
        <div className="flex justify-between border-t py-2">
          <div>총가격</div>
          <div>
            {numberWithCommas(
              optionItem.reduce<number>((acc, cur) => acc + cur.totalPrice, 0) +
                (shopInfo.option_products?.length > 0
                  ? shopInfo.current_price
                  : 0)
            )}{' '}
            원
          </div>
        </div>
        <div className="flex gap-4 w-full justify-center rounded-md">
          {gubun !== 'course' && (
            <Button
              disabled={buttonDisabled}
              className="w-full border border-purple5 bg-white text-purple5 hover:bg-purple0 shadow-none hover:shadow-none rounded-[4px]"
              onClick={eventCartHandle}
            >
              장바구니
            </Button>
          )}
          <Button
            disabled={buttonDisabled}
            className="w-full bg-purple5 hover:bg-purple6 shadow-none hover:shadow-none rounded-[4px]"
            onClick={eventPurchaseHandle}
          >
            구매하기
          </Button>
        </div>
      </section>
    </Fragment>
  );
};
