import { CookieKey } from '../../../util/cookie';
import { createCookie } from '../../../util/cookie';
import instance from '../../axios/utils.ts/instance';
/**
 * 토큰 만료 확인
 * @returns EMPTY : 토큰이 없음, SUCCESS : 토큰이 유효함, EXPIRED : 토큰이 만료됨
 */
export const checkToken = async () => {
  const { setCookie, getCookie } = createCookie(CookieKey.USER_INFO);

  try {
    if (!localStorage.getItem('accessToken')) return 'EMPTY';
    const res = await instance.get('/v1/auth/user-authorization?check=true');
    if (res.data.statusCode === 200) {
      setCookie(
        {
          id: res.data.data.id,
          uid: res.data.data.uid,
          accessToken: localStorage.getItem('accessToken'),
          studentId: res.data.data.studentId,
        },
        1
      );
      return 'SUCCESS';
    }
    return 'EXPIRED';
  } catch (error) {
    return 'EXPIRED';
  }
};
