import { isWithin } from '../../../api/utils/util-func';
import { carouselItem } from '../../../constants/types';
import { useNavigate } from 'react-router-dom';

const useCarousel = (list: carouselItem[]) => {
  const navigate = useNavigate();

  // 현재 날짜가 배너 기간 내에 있는 경우 필터링
  const isDateValid = (item: carouselItem) => {
    const { date } = item;
    if (!date) return true;
    return isWithin(date.start, date.end);
  };

  // 기간 내에 있는 배너와 디폴트 배너를 보여준다.
  const filteredList = list.filter(isDateValid);

  // 링크 클릭 시 이동
  const clickHandler = (link: string, type?: '_blank') => {
    if (type === '_blank') {
      window.open(link, type);
    } else {
      navigate(link);
    }
  };
  return {
    list: filteredList,
    clickHandler,
  };
};

export default useCarousel;
