import { Button, Table, TableColumnsType } from 'antd';
import React from 'react';
import { assignedLmsTodosById } from '../../../constants/types/lms-type';
import { getTodoStateDetailById } from '../../../api/utils/lms/lmsApi';
import { TODO_SUBTYPE } from '../../../data/statics-datas';
import { openNewWindow } from '../../../api/utils/util-func';
import { CookieKey, createCookie } from '../../../util/cookie/createCookie';
import {
  CookieTypeNewRecitation,
  CookieTypeUserInfo,
} from '../../../util/cookie';
import createNewWindow from '../../../controllers/createNewWindow';

interface TodoExpandedRowByIdProps {
  classId: string;
  toDoType: string;
  toDoStatus: string;
  id: number;
  subType: string;
}
const TodoExpandedRowById = ({
  classId,
  toDoType,
  toDoStatus,
  id,
  subType,
}: TodoExpandedRowByIdProps) => {
  const [data, setData] = React.useState<assignedLmsTodosById[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    getTodoStateDetailById(classId, toDoType, toDoStatus, id).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [classId, toDoType, toDoStatus, id]);

  const { setCookie } = createCookie<CookieTypeNewRecitation>(
    CookieKey.NEW_RECITATION
  );
  const { getCookie } = createCookie<CookieTypeUserInfo>(CookieKey.USER_INFO);

  const showResult = (row: assignedLmsTodosById) => {
    if (row.passDate) {
      setCookie(
        {
          student: {
            id: '',
            uid: '',
          },
          teacher: {
            id: getCookie().id,
            uid: getCookie().uid,
            classId: classId,
          },
          todo: {
            id: row.id,
            type: row.type,
          },
          accessToken: localStorage.getItem('accessToken'),
        },
        1 / 24
      );
      createNewWindow(row.result, 1440, 1440 / 1080);
    }
  };

  const selectCol = () => {
    // 데이터가 있을 때만 컬럼을 선택하도록
    // 할일 종류에 따라서 컬럼을 선택
    switch (subType.toUpperCase()) {
      case 'RECITATION':
      case 'NEW_RECITATION':
        return columnsRECITATION(toDoStatus, subType, showResult);
      case 'JEBSPL':
        return columns;
      case 'JEBSON':
        return columnsWithLesson;
      default:
        console.error('SubType Error', subType);
        return columns;
    }
  };

  return (
    <Table
      columns={selectCol()}
      dataSource={data}
      rowKey={(row) => row.id}
      size="small"
      loading={loading}
      pagination={
        data.length > 10
          ? {
              pageSize: 10,
              style: {
                marginTop: 30,
                marginBottom: 0,
                justifyContent: 'center',
              },
            }
          : false
      }
    />
  );
};

export default TodoExpandedRowById;

const columnsRECITATION = (
  status?: string,
  subType?: string,
  showResult?: (row: assignedLmsTodosById) => void
): TableColumnsType<assignedLmsTodosById> => [
  {
    title: '할일명',
    align: 'center',
    render: (row) =>
      TODO_SUBTYPE.find((item) => item.subType === row.type)?.name || row.type,
  },
  {
    title: '레벨',
    dataIndex: 'book',
    align: 'center',
  },
  {
    title: '북',
    dataIndex: 'title',
    align: 'center',
  },
  {
    title: '완료일',
    render: (row) =>
      row.completedDate
        ? new Date(row.completedDate).toLocaleDateString()
        : '-',
    align: 'center',
  },
  {
    title: '응시결과',
    render: (_, row) => (
      <Button onClick={() => showResult(row)}>결과보기</Button>
    ),
    align: 'center',
    hidden:
      (status !== 'COMPLETED' && status !== 'CLOSED') ||
      subType !== 'new_recitation',
  },
];

const columnsWithLesson: TableColumnsType<assignedLmsTodosById> = [
  {
    title: '할일명',
    dataIndex: 'type',
    align: 'center',
  },
  {
    title: '북/챕터',
    dataIndex: 'book',
    align: 'center',
  },
  {
    title: '레슨',
    dataIndex: 'lesson',
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    align: 'center',
  },
  {
    title: '완료일',
    render: (row) =>
      row.completedDate
        ? new Date(row.completedDate).toLocaleDateString()
        : '-',
    align: 'center',
  },
];
const columns: TableColumnsType<assignedLmsTodosById> = [
  {
    title: '할일명',
    dataIndex: 'type',
    align: 'center',
  },
  {
    title: '북/챕터',
    dataIndex: 'book',
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    align: 'center',
  },
  {
    title: '완료일',
    render: (row) =>
      row.completedDate
        ? new Date(row.completedDate).toLocaleDateString()
        : '-',
    align: 'center',
  },
];
