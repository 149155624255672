import dayjs, { Dayjs } from 'dayjs';

export type PopupType = {
  img: string;
  size: number;
  link?: string;
  type?: '_blank';
  default: boolean;
  date?: {
    start: Dayjs;
    end: Dayjs;
  };
};

export const popupList: PopupType[] = [
  {
    img: process.env.PUBLIC_URL + 'images/home/popup/20240716.jpg',
    link: 'https://jebs-notice.oopy.io/3445e325-9c88-40d5-a77f-a862a8e46af5',
    size: 480,
    default: true,
  },
  {
    img: process.env.PUBLIC_URL + 'images/home/popup/20250121.png',
    size: 480,
    default: false,
    date: {
      start: dayjs('2025-01-21'),
      end: dayjs('2025-02-03'),
    },
  },
];
