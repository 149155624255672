import React from 'react';
import HomeschoolSection1 from './HomeschoolSection1';
import HomeschoolSection2 from './HomeschoolSection2';
import HomeschoolSection3 from './HomeschoolSection3';
import HomeschoolSection4 from './HomeschoolSection4';
import HomeschoolSection5 from './HomeschoolSection5';
import HomeschoolSection6 from './HomeschoolSection6';
import HomeschoolSection7 from './HomeschoolSection7';
import HomeschoolSection8 from './HomeschoolSection8';
import HomeschoolSection9 from './HomeschoolSection9';
import HomeschoolSection10 from './HomeschoolSection10';
import HomeschoolSection11 from './HomeschoolSection11';
import HomeschoolEvent_25_1 from './event/HomeschoolEvent_25_1';
import HomeschoolEvent_25_2 from './event/HomeschoolEvent_25_2';
import { isWithin } from '../../../api/utils/util-func';

const HomeschoolMain = () => {
  return (
    <div>
      {/* 월별 프로모션 섹션 */}
      {isWithin('2025-01-01', '2025-01-31') ? (
        <HomeschoolEvent_25_1 />
      ) : (
        <HomeschoolEvent_25_2 />
      )}
      {/* <HomeschoolSection2 /> */}

      {/* 영어교육의 해답, 왜 젭스홈스쿨에 있을까? */}
      <HomeschoolSection3 />

      {/* 홈스쿨 연령별 맞춤코스, 이렇게 구성되어 있어요! */}
      <HomeschoolSection4 />

      {/* 젭스영어성경홈스쿨 코스 TIP */}
      <HomeschoolSection5 />

      {/* 젭스홈스쿨 패키지 코스 구성 */}
      <HomeschoolSection6 />

      {/* 젭스영어성경홈스쿨, 이렇게 시작하세요! */}
      <HomeschoolSection7 />

      {/* 홈스쿨 운영 절차 */}
      <HomeschoolSection8 />

      {/* 회원에게만 주어지는 풍성한 혜택! */}
      <HomeschoolSection9 />

      {/* 링크 */}
      <HomeschoolSection10 />

      {/* FAQ */}
      <HomeschoolSection11 />
    </div>
  );
};

export default HomeschoolMain;
