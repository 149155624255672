const createNewWindow = (link: string, width: number, ratio: number) => {
  // 스크린 사이즈
  const screenWidth = window.screen.width;

  // 스크린 보다 클 경우 스크린 사이즈로 설정
  const newWidth = width > screenWidth ? screenWidth : width;
  // 가로가 변경된 경우 세로도 변경
  const newHeight = newWidth / ratio;

  console.log(newWidth, newHeight);
  const newWindow = window.open(
    link,
    'TestView',
    `location=no, menubar=no,width=${newWidth},height=${newHeight}`
  );
  return newWindow;
};

export default createNewWindow;
