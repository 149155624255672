import { PencilIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import Buttons from '../../../../components/common/Buttons';
import TextWinds from '../../../../components/common/TextWinds';
import { useParams, useSearchParams } from 'react-router-dom';
import { ToDoMgmtSubType } from '../../../../enum';
import RegisterTodoPage from './RegisterTodoPage';
import RegisterTodoDetail from './RegisterTodoDetail';
import TodoStatusPage from './TodoStatusPage';
import HFbutton from '../../../../components/common/input/HFbutton';
import { getSubTypeName, openNewWindow } from '../../../../api/utils/util-func';

const TodoMgmt = () => {
  const subType = useParams().subType.toUpperCase();
  const [params, setParams] = useSearchParams();
  type StageType = 'todo' | 'state' | 'regist';
  const [stageTab, setStageTab] = useState<StageType>('todo');

  useEffect(() => {
    const stage = params.get('stage');
    setStageTab((stage as StageType) || 'todo');
  }, [params]);

  const setSearchParams = (key: string, value: string) => {
    params.forEach((value, key) => {
      if (key !== 'stage') {
        params.delete(key);
      }
    });
    params.set(key, value);
    setParams(params);
  };

  const getDescription = (
    subType: string
  ): {
    desc1: string;
    desc2: string;
  } => {
    switch (subType) {
      case ToDoMgmtSubType.RECITATION:
        return {
          desc1:
            '국내최초! AI로 평가되는 암송인증제! 응시권을 사용하여 학생에게 인증제 할 일을 등록해주세요.',
          desc2: '학생은 본인 계정으로 접속하여 인증제를 이용할 수 있습니다. ',
        };
      case ToDoMgmtSubType.NEW_RECITATION:
        return {
          desc1:
            '[ BETA ] 국내최초! AI로 평가되는 암송인증제! 응시권을 사용하여 학생에게 인증제 할 일을 등록해주세요.',
          desc2:
            '[ BETA ] 학생은 본인 계정으로 접속하여 인증제를 이용할 수 있습니다. ',
        };
      case ToDoMgmtSubType.JEBSON:
        return {
          desc1: '온라인 복습 과제, 젭스온!',
          desc2:
            '학생에게 젭스온 할 일을 등록해 주세요. 학생은 본인 계정으로 접속하여 젭스온을 이용할 수 있습니다.',
        };
      case ToDoMgmtSubType.JEBSPL:
        return {
          desc1: '주일학교용 온라인 과제, 젭스플!',
          desc2:
            '이용권을 사용하여 학생에게 젭스플 할 일을 등록해주세요. 학생은 본인 계정으로 접속하여 젭스플을 이용할 수 있습니다.',
        };
      default:
        return {
          desc1: '',
          desc2: '',
        };
    }
  };

  return (
    <div className="pt-6 xl:px-5 flex flex-col gap-3">
      <header className="px-5 xl:px-0">
        <TextWinds type="title_h3" className="mb-[2px]">
          {getSubTypeName(subType)} 관리
        </TextWinds>
        <TextWinds type="content_body3">
          {getDescription(subType).desc1}
          <br />
          {getDescription(subType).desc2}
        </TextWinds>
        {(subType === ToDoMgmtSubType.RECITATION ||
          subType === ToDoMgmtSubType.NEW_RECITATION) && (
          <div className="w-full xl:max-w-[200px] mt-3">
            <HFbutton
              height={36}
              onClick={() =>
                openNewWindow(
                  'https://jebs-notice.oopy.io/16f768f7-7a39-8042-884c-f4c58f453684'
                )
              }
            >
              AI인증제 안내 (BETA 출시)
            </HFbutton>
          </div>
        )}
      </header>
      {stageTab === 'regist' ? (
        // 할일 등록 상세 페이지
        <RegisterTodoDetail />
      ) : (
        <section className="bg-white py-4 xl:p-4 xl:pt-6 xl:shadow-card xl:rounded-lg">
          <div className="flex gap-x-4 mx-5 pb-[18px] border-b border-gray4 xl:mx-0">
            <Buttons
              type="text"
              color={stageTab === 'todo' ? 'purple5' : 'gray7'}
              onClick={() => setSearchParams('stage', 'todo')}
              className="gap-x-1"
            >
              <PencilIcon className="w-6 h-6" />
              <TextWinds
                type="title_h2"
                color={stageTab === 'todo' ? 'purple5' : 'gray7'}
              >
                할 일 등록
              </TextWinds>
            </Buttons>
            <Buttons
              type="text"
              color={stageTab === 'state' ? 'purple5' : 'gray7'}
              onClick={() => setSearchParams('stage', 'state')}
              className="gap-x-1"
            >
              <img
                src={
                  process.env.PUBLIC_URL + stageTab === 'state'
                    ? '/svg/icon-chart-on.svg'
                    : '/svg/icon-chart.svg'
                }
                alt=""
              />
              <TextWinds
                type="title_h2"
                color={stageTab === 'state' ? 'purple5' : 'gray7'}
              >
                할 일 현황
              </TextWinds>
            </Buttons>
          </div>
          {stageTab === 'todo' ? (
            <RegisterTodoPage />
          ) : stageTab === 'state' ? (
            <TodoStatusPage />
          ) : null}
        </section>
      )}
    </div>
  );
};

export default TodoMgmt;
