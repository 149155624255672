import {
  ClipboardIcon,
  Cog8ToothIcon,
  HomeIcon,
  UserIcon,
  BookmarkIcon,
  MicrophoneIcon,
  BookOpenIcon,
} from '@heroicons/react/24/outline';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { Link } from 'react-router-dom';

const SidebarTodoItem = (subType: string) => {
  if (subType === 'home') {
    return [
      {
        label: <Link to={`./todo-management/recitation`}>인증제</Link>,
        key: 'recitation',
      },
      {
        label: (
          <Link to={`./todo-management/new_recitation`}>AI 인증제 Beta</Link>
        ),
        key: 'new_recitation',
      },
      {
        label: <Link to={`./todo-management/jebson/`}>젭스온</Link>,
        key: 'jebson',
      },
    ];
  } else if (subType === 'church') {
    return [
      {
        label: <Link to={`./todo-management/jebspl`}>젭스플</Link>,
        key: 'jebspl',
      },
    ];
  } else {
    return undefined;
  }
};

const SidebarTodoList = (subType: string) => {
  if (subType === '') return [];

  const result: ItemType<MenuItemType>[] = [
    {
      label: '할 일 관리',
      key: 'todo',
      icon: <ClipboardIcon className="h-6 w-6" />,
      children: SidebarTodoItem(subType),
    },
  ];

  if (subType === 'home') {
    result.push({
      label: '인증제 내역',
      key: 'recitation-history',
      icon: <MicrophoneIcon className="h-6 w-6" />,
      children: [
        // {
        //   label: <Link to={`./recitation/dashboard`}>대쉬보드</Link>,
        //   key: 'recitation-dashboard',
        // },
        {
          label: <Link to={`./recitation/list`}>현황 목록</Link>,
          key: 'recitation-list',
        },
      ],
    });
  }
  return result;
};

export const SidebarItems = (menuExposure: string) => [
  {
    label: <Link to={`./dashboard`}>클래스 메인</Link>,
    key: 'dashboard',
    icon: <HomeIcon className="h-6 w-6" />,
  },
  {
    label: <Link to={`./take-class`}>수업하기</Link>,
    key: 'take-class',
    icon: <BookOpenIcon className="h-6 w-6" />,
  },
  // {
  //   label: '수업 관리',
  //   key: 'management',
  //   icon: <ClipboardIcon className="h-6 w-6" />,
  //   children: [
  //     {
  //       label: <Link to={`./course-management`}>코스관리</Link>,
  //       key: 'course-management',
  //     },
  //     {
  //       label: <Link to={`./take-class`}>수업하기</Link>,
  //       key: 'take-class',
  //     },
  //   ],
  // },
  ...SidebarTodoList(menuExposure),
  {
    label: '학생',
    key: 'student',
    icon: <UserIcon className="h-6 w-6" />,
    children: [
      {
        label: <Link to={`./signup-management`}>학생 관리</Link>,
        key: 'signup-management',
      },
      {
        label: <Link to={`./migration`}>구버전 학생 가져오기</Link>,
        key: 'migration',
      },
    ],
  },
  {
    label: <Link to={`./reference-room`}>자료실</Link>,
    key: 'reference-room',
    icon: <BookmarkIcon className="h-6 w-6" />,
  },
  {
    label: '클래스 설정',
    key: '5',
    icon: <Cog8ToothIcon className="h-6 w-6" />,
    onClick: () => {
      alert('준비중인 기능입니다.');
    },
  },
];
